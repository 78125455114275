@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

p {
  font-size: 1.3rem;
  /* word-break: keep-all; */
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  background: #000000;
  font-family: "Quicksand", sans-serif;
  overflow-x: hidden;
  text-size-adjust: 100%;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
