* {
  color: #ffffff;
}

.frame {
  width: 76% !important;
  margin: auto !important;
}

@media (min-width: 768px) {
  .training {
    margin-top: 4% !important;
  }
  .training .img1 {
    height: 90%;
    width: 100%;
  }
  .training .content1 .heading1 {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 125.6%;
    margin-bottom: 2%;
  }
  .training .content1 .description {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 152.6%;
  }
  .img2 {
    height: 80% !important;
    width: 100% !important;
  }
  .programheading {
    text-align: center;
    margin-top: 3% !important;
  }
  .programheading p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 125.6%;
    color: white;
  }
  .program {
    box-sizing: border-box;
    margin-top: 5% !important;
    display: flex;
    justify-content: space-between;
  }
  .program .box {
    width: 30.1%;
  }
  .program .program1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.16) 100%);
    border: 2px solid #ffffff;
    filter: blur(0.5px);
    -webkit-backdrop-filter: blur(13.5px);
            backdrop-filter: blur(13.5px);
    text-align: center;
  }
  .program .program1 img {
    width: 96%;
    height: 96%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .program .progTitle1 {
    margin-top: 5% !important;
  }
  .program .progTitle1 p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 136.6%;
    color: #ffffff;
  }
  .case1 {
    margin-top: 18% !important;
  }
  .name {
    height: 8vh;
    width: 90%;
  }
}
.name {
  background: linear-gradient(183.35deg, rgba(65, 65, 65, 0) -1.12%, rgba(46, 46, 46, 0.6) 99.16%);
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.39);
  padding: 2%;
}

.submitbtn {
  background-color: red;
  background: #f44336;
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  color: #ffffff;
  height: 8vh;
  width: 20vw;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  border-radius: 10px;
  border: none;
  margin: auto;
}

.textfield {
  height: 24vh !important;
}

@media (min-width: 576px) and (max-width: 767.99px) {
  .case1 {
    margin-top: 30% !important;
  }
  .name {
    width: 100% !important;
    height: 8vh;
    margin: 4% 0;
  }
  .textfield {
    width: 97% !important;
  }
  .programheading {
    margin-top: 16% !important;
  }
}
@media (max-width: 575.98px) {
  .training {
    margin-top: 12% !important;
  }
  .training .img1 {
    height: 100%;
    width: 100%;
    margin: auto !important;
  }
  .training .content1 .heading1 {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 125.6%;
    margin: 4% 0 !important;
  }
  .training .content1 .description {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 152.6%;
  }
  .programheading {
    text-align: center;
    margin-top: 20% !important;
  }
  .programheading p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 125.6%;
    color: white;
  }
  .program {
    margin-top: 12%;
  }
  .program .box {
    margin-top: 12% !important;
  }
  .program .program1 {
    height: 34vh;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.16) 100%);
    border: 2px solid #ffffff;
    filter: blur(0.5px);
    -webkit-backdrop-filter: blur(13.5px);
            backdrop-filter: blur(13.5px);
    text-align: center;
  }
  .program .program1 img {
    height: 30vh;
    width: 64vw;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .program .progTitle1 {
    margin: 3% 0 !important;
  }
  .program .progTitle1 p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 136.6%;
    color: #ffffff;
  }
  .submitbtn {
    height: 8vh !important;
    width: 69vw;
    font-size: 1rem !important;
    margin-left: 3%;
  }
  .input1 {
    margin-top: 2% !important;
  }
  .input1 .field {
    margin-top: 12% !important;
  }
  .name {
    width: 100%;
    padding: 2%;
  }
  .textfield {
    width: 92% !important;
  }
  .field1 {
    margin-top: 12% !important;
  }
}
.dropbtn {
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  background-color: rgba(45, 44, 44, 0);
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 24px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #818181;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #5c5c5c;
}

.dropdown:hover .dropdown-content {
  display: block;
}/*# sourceMappingURL=training_tse.css.map */